
export default {
  name: 'LayoutComponent',
  props: {
    headerMenuPrimaryItems: {
      type: Object,
      default: null,
    },
    headerMenuSecondaryItems: {
      type: Object,
      default: null,
    },
    headerMenuSocialItems: {
      type: Object,
      default: null,
    },
    headerBroadcasterLogo: {
      type: String,
      default: 'npostart',
    },
    headerThemeTabs: {
      type: Boolean,
      default: true,
    },
    userMenuHidden: {
      type: Boolean,
      default: false,
    },
    notificationMenuHidden: {
      type: Boolean,
      default: false,
    },
    corporateHidden: {
      type: Boolean,
      default: false,
    },
    membershipHidden: {
      type: Boolean,
      default: false,
    },
    footerShortcuts: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default:null,
    },
    headerColor:{
      type: String,
      default:"purple-700",
    },
    topColor: {
      type: String,
      default:"purple-700",
    },
    topTextColor: {
      type: String,
      default:"white",
    },
    bottomColor: {
      type: String,
      default:"purple-600",
    },
    bottomTextColor: {
      type: String,
      default:"purple-50",
    },
  },
  data () {
    return {
      keyword: null,
      isMounted: false,
      isMenuOpen: false,
      isNotificationMenuOpen: false,
      showNotificationAlert: false,
      isUserMenuOpen: false,
      isFeatureLoginEnabled: this.$config.featureLoginEnabled,
      headerActiveTab: null,
      broadcasterLogo: this.headerBroadcasterLogo,
      collapseToggleText: {
        collapsed: "Meer thema's",
        expanded: "Minder thema's",
      },
      userMenuItems: [
        {
          label: 'Account',
          path: `${this.$config.baseUrlMijnEO}/account`,
          isExternal: true,
        },
        {
          label: 'Profiel',
          path: '/profiel',
        }, {
          label: 'Uitloggen',
          action: 'logout',
        },
      ],
    }
  },
  computed: {
    pathWithoutTrailingSlash () {
      return this.$route?.path?.replace(/\/$/, '')
    },
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    isLoggedIn () {
      return this.$auth.loggedIn
    },
  },
  watch: {
    async $route () {
      if (this.isUserMenuOpen) this.isUserMenuOpen = false
      if (this.isLoginDialogOpen) this.isLoginDialogOpen = false
    },
  },
  created() {
    this.$nuxt.$on('openLoginDialog', () => {
      this.isLoginDialogOpen = !this.isLoginDialogOpen
    })
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    closeMenu () {
      this.isMenuOpen = false
    },
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    toggleNotifications () {
      this.isUserMenuOpen = false
      this.isNotificationMenuOpen = !this.isNotificationMenuOpen
    },
    toggleUserMenu () {
      this.isNotificationMenuOpen = false
      this.isUserMenuOpen = !this.isUserMenuOpen
    },
    onNavigate () {
      this.isMenuOpen = false
    },
    onNotification (value) {
      this.showNotificationAlert = value
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
    logout() {
      this.$auth.logout()
    },
  },
}
