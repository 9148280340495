import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=1cfd3b76&scoped=true"
import script from "./Layout.vue?vue&type=script&lang=js"
export * from "./Layout.vue?vue&type=script&lang=js"
import style0 from "./Layout.vue?vue&type=style&index=0&id=1cfd3b76&prod&lang=scss"
import style1 from "./Layout.vue?vue&type=style&index=1&id=1cfd3b76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cfd3b76",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NotificationButton: require('/app/node_modules/@eo/nuxt-notification/lib/components/NotificationButton/NotificationButton.vue').default,NotificationMenu: require('/app/node_modules/@eo/nuxt-notification/lib/components/NotificationMenu/NotificationMenu.vue').default,UserMenu: require('/app/node_modules/@eo/nuxt-mijneo/lib/components/UserMenu.vue').default,EoFollowGuideDialog: require('/app/node_modules/@eo/nuxt-follow/lib/components/EoFollowGuideDialog/EoFollowGuideDialog.vue').default})
