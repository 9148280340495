/**
 * New place to store constants that are used on several places.
 * When a constant is only used in one place, don't use this file, but just directly inject the constant where it needs to be.
 * This to avoid clutter and old constants from old projects.
 */
export default {
  APP_ENV: process.env.APP_ENV || 'production',
  APP_VERSION: process.env.BITBUCKET_TAG || `v0.0.0-rc.${process.env.BITBUCKET_BUILD_NUMBER}`,
  BASE_URL: (process.env.BITBUCKET_PR_ID && `https://pr-${process.env.BITBUCKET_PR_ID}-eo-home.feat.eo.nl`) || process.env.BASE_URL || 'https://www.eo.nl',
  BASE_URL_CANONICAL: process.env.BASE_URL_CANONICAL || 'https://www.eo.nl',
  EO_API_HOST: process.env.EO_API_HOST || 'https://tst-api.eo.nl',
  NEW_EO_API_HOST: process.env.NEW_EO_API_HOST || 'https://tst-api.eo.nl',
  EO_API_VERSION: 'v1',
  PREPR_ENVIRONMENT_DOMAIN_BIJBELSENAMEN: process.env.PREPR_ENVIRONMENT_DOMAIN_BIJBELSENAMEN || 'bijbelsenamen-eo',
  PREPR_ENVIRONMENT_DOMAIN_EO: process.env.PREPR_ENVIRONMENT_DOMAIN_EO || 'home-eo',
  PREPR_ENVIRONMENT_DOMAIN_EVA: process.env.PREPR_ENVIRONMENT_DOMAIN_EVA || 'eva-eo',
  PREPR_ENVIRONMENT_DOMAIN_GENERIEK: process.env.PREPR_ENVIRONMENT_DOMAIN_GENERIEK || 'generieke-content-eo',
  PREPR_ENVIRONMENT_DOMAIN_METTERDAAD: process.env.PREPR_ENVIRONMENT_DOMAIN_METTERDAAD || 'metterdaad-eo',
  PREPR_ENVIRONMENT_DOMAIN_VISIE: process.env.PREPR_ENVIRONMENT_DOMAIN_VISIE || 'visie-eo',
  PREPR_ENVIRONMENT_DOMAIN_LAZARUS: process.env.PREPR_ENVIRONMENT_DOMAIN_LAZARUS || 'lazarus-eo',
  PREPR_ENVIRONMENT_ID_EO: '94dcecf2-1725-436c-9205-04f20bcffef0',
  PREPR_ENVIRONMENT_ID_EVA: '3fc256c0-b074-4780-b401-20e562827537',
  PREPR_ENVIRONMENT_ID_METTERDAAD: '48c62830-e604-48fa-bc4b-693a8962d21e',
  PREPR_ENVIRONMENT_ID_VISIE: 'c21bea32-65a1-4b4b-a9d8-5f4544b4ff05',
  PREPR_ENVIRONMENT_ID_LAZARUS: '55177197-688f-4dde-8d1f-509f61babbe7',
  PREPR_COMMUNITY_ID_EO: process.env.PREPR_COMMUNITY_ID_EO || '78915b89-f177-440a-8f8f-62dfea174d62',
  PREPR_COMMUNITY_ID_EVA: process.env.PREPR_COMMUNITY_ID_EVA || 'ef6cd8a6-9fcd-49e0-85c2-03fdd38a710b',
  PREPR_COMMUNITY_ID_METTERDAAD: process.env.PREPR_COMMUNITY_ID_METTERDAAD || '52e68b37-0573-49a5-b7bd-ad6f5c061878',
  PREPR_COMMUNITY_ID_VISIE: process.env.PREPR_COMMUNITY_ID_VISIE || '943f11ab-2031-4eda-b77d-0ae968c4518b',
  PLAYER_KEY: process.env.PLAYER_KEY,
  PLAYER_ANALYTICS_KEY: process.env.PLAYER_ANALYTICS_KEY,
  TRACKING_FIELD_HOME: 'home',
  TRACKING_FIELD_EO: 'eo',
  TRACKING_FIELD_EVA: 'eva',
  TRACKING_FIELD_METTERDAAD: 'metterdaad',
  TRACKING_FIELD_VISIE: 'visie',
  STORIES_BIJBELSE_NAMEN: {
    'beginletter-a': {
      id: '25b712ba-c0e6-4a65-943b-9e8426b81c64', // content bundle
      label: 'A',
      type: 'letter',
    },
    'beginletter-b': {
      id: '30c8cb16-b934-4598-afa2-5b295ea478bd', // content bundle
      label: 'B',
      type: 'letter',
    },
    'beginletter-c': {
      id: 'd8b0c823-a470-4fec-b752-7a7702091f85', // content bundle
      label: 'C',
      type: 'letter',
    },
    'beginletter-d': {
      id: '51537f14-6556-4592-814e-cb6a930aa34c', // content bundle
      label: 'D',
      type: 'letter',
    },
    'beginletter-e': {
      id: '4eb104ef-3e71-4949-b23c-ac9446c46c5c', // content bundle
      label: 'E',
      type: 'letter',
    },
    'beginletter-f': {
      id: '8f54a3e3-7f98-4b65-a0dd-511b7562bf75', // content bundle
      label: 'F',
      type: 'letter',
    },
    'beginletter-g': {
      id: 'dea8e923-e4ae-4b16-b827-cf3f43fb8cad', // content bundle
      label: 'G',
      type: 'letter',
    },
    'beginletter-h': {
      id: 'a88bbf2c-eb92-4e95-9740-a590bd0ff263', // content bundle
      label: 'H',
      type: 'letter',
    },
    'beginletter-i': {
      id: 'f4b29081-f2ee-4d83-9ef2-d186109a965a', // content bundle
      label: 'I',
      type: 'letter',
    },
    'beginletter-j': {
      id: 'fbe2997d-bd7e-4eab-889a-05d69a080dae', // content bundle
      label: 'J',
      type: 'letter',
    },
    'beginletter-k': {
      id: '04f36a6c-9650-4a7e-9f6f-fc39e0c6fc4d', // content bundle
      label: 'K',
      type: 'letter',
    },
    'beginletter-l': {
      id: '921a0f10-6928-41e4-87f9-3db49688424c', // content bundle
      label: 'L',
      type: 'letter',
    },
    'beginletter-m': {
      id: 'b1f13c45-d36d-4db7-bd02-b0301755549a', // content bundle
      label: 'M',
      type: 'letter',
    },
    'beginletter-n': {
      id: 'afbb6e9c-e39c-49e2-bd0c-7549b0986fbc', // content bundle
      label: 'N',
      type: 'letter',
    },
    'beginletter-o': {
      id: '70611298-4204-4005-84e8-c00fbdd4b41b', // content bundle
      label: 'O',
      type: 'letter',
    },
    'beginletter-p': {
      id: '0c30b289-ac0d-4ae0-a5fa-6d57b6769bbe', // content bundle
      label: 'P',
      type: 'letter',
    },
    'beginletter-q': {
      id: '6fce82b2-2b08-400d-b6fb-6b5ed4f43d7a', // content bundle
      label: 'Q',
      type: 'letter',
    },
    'beginletter-r': {
      id: 'd3fd0df8-0702-4baf-bf9d-9c85533f2fa3', // content bundle
      label: 'R',
      type: 'letter',
    },
    'beginletter-s': {
      id: '7b58e6d4-aca5-41bb-9d7f-3a22875b3d63', // content bundle
      label: 'S',
      type: 'letter',
    },
    'beginletter-t': {
      id: '207c64e5-039d-49ec-b7cf-55617bac0add', // content bundle
      label: 'T',
      type: 'letter',
    },
    'beginletter-u': {
      id: '9c743690-813b-4ead-a594-51a044030990', // content bundle
      label: 'U',
      type: 'letter',
    },
    'beginletter-v': {
      id: '346f4cd3-cc2e-43d8-bfc1-16eb0e16e120', // content bundle
      label: 'V',
      type: 'letter',
    },
    'beginletter-w': {
      id: 'f7a21fd8-e6d8-4d3b-b903-f7cc388b344a', // content bundle
      label: 'W',
      type: 'letter',
    },
    'beginletter-x': {
      id: 'ce6a7d4d-3d8a-42f4-92f3-1167a37038fc', // content bundle
      label: 'X',
      type: 'letter',
    },
    'beginletter-y': {
      id: '802ea484-4f79-48de-b412-c62ba44e9805', // content bundle
      label: 'Y',
      type: 'letter',
    },
    'beginletter-z': {
      id: '767dcd85-ab6d-4cda-b7de-092bf794ff31', // content bundle
      label: 'Z',
      type: 'letter',
    },
    'jongensnamen': {
      id: '3e219155-18ad-41da-bccc-d11d52a2e2dd', // content bundle
      label: 'Jongensnamen',
      type: 'sex',
    },
    'meisjesnamen': {
      id: '77b18404-4334-4dff-908a-795620512aa0', // content bundle
      label: 'Meisjesnamen',
      type: 'sex',
    },
    'populaire-jongensnamen': {
      id: '9943e0e4-362f-4d11-a121-7cf6fafeb57b', // content bundle
      label: 'Populaire jongensnamen',
      type: 'sex',
    },
    'populaire-meisjesnamen': {
      id: '9cf05ba3-04b4-447e-b00c-d694e518f4eb', // content bundle
      label: 'Populaire meisjesnamen',
      type: 'sex',
    },
    'grieks': {
      id: '0c485852-edac-46c4-b3e7-60714b173278', // content bundle
      label: 'Grieks',
      type: 'origin',
    },
    'hebreeuws': {
      id: 'c7cf8efc-b12c-4663-a2df-2729a09a980a', // content bundle
      label: 'Hebreeuws',
      type: 'origin',
    },
    'nieuwe-testament': {
      id: '5426280d-5415-486c-bf4e-5448b8fc599e', // content bundle
      label: 'Nieuwe testament',
      type: 'origin',
    },
    'oude-testament': {
      id: '366de197-6780-412d-93c2-28baa85f8a6e', // content bundle
      label: 'Oude testament',
      type: 'origin',
    },
  },
}
